import { defineStore } from "pinia";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { getLocalStorageReac, setLocalStorageReac } from "@/assets/js/helpers";
import api from "@/api";
import notifIcon from "@/assets/image/logo.jpg";
import { CWebsocket } from "@/assets/js/websocketHelper";
import type { IChatUser } from "@/pages/live-chat.vue";
// import { useWindowSize } from "@vueuse/core";
dayjs.extend(utc);
dayjs.extend(timezone);

interface IChatUserNotif {
	cus_id: number;
	total_cms: number;
}

interface IChatData {
	total: number;
	chatusers: number[];
	totalByChatusers: IChatUserNotif[];
}

interface IChatMessage {
	cms_id?: number;
	cms_message: string;
	cus_id?: number;
	cms_date: string;
	usr_id?: number;
	cms_from?: "user" | "admin";
}

export const useWebSocketStore = defineStore("web-socket", () => {
	const notificationsData = ref<IChatData | null>(null);
	const totalNotifications = ref<number | null>(null);
	const chatsNotifications = ref<number[] | null>(null);
	const notificationsByChat = ref<IChatUserNotif[] | null>(null);
	const notifBool = ref(0);
	const availBool = ref(0);
	const wsText = ref("");
	const wsInstance = ref<CWebsocket | null>(null);
	const domain = "442c123.mars-t.mars-hosting.com"; // DEV
	// const domain = "435t123.mars2.mars-hosting.com"; // PROD
	const selectedUserId = ref<number | null>(null);
	const chatusers = ref<IChatUser[] | null>(null);
	const chatMessages = ref<IChatMessage[] | null>(null);
	const newMsgCounter = ref(1);
	const typingBool = ref(false);

	async function getChatNotifications() {
		try {
			const res = await api.chatMessagesUnseenGet();
			notificationsData.value = res.data.data;
			// console.log(res.data.data);
			if (notificationsData.value) {
				totalNotifications.value = notificationsData.value.total;
				chatsNotifications.value = notificationsData.value.chatusers;
				notificationsByChat.value = notificationsData.value.totalByChatusers;
			}
			return true;
		} catch (err: any) {
			console.warn(err.message);
			return false;
		}
	}

	async function enableNotif() {
		if (notifBool.value === 0) {
			notifBool.value = 1;
		} else if (notifBool.value === 1) {
			notifBool.value = 0;
		}

		const params = {
			notifications_sound: notifBool.value,
		};

		try {
			const res = await api.adminSettingsEdit(params);
			console.warn(res.data);
			setLocalStorageReac("setting-notif", notifBool.value);
			getAdminSettings();
			return true;
		} catch (err: any) {
			console.warn(err.message);
			return false;
		}
	}

	async function setAvailable() {
		if (availBool.value === 0) {
			availBool.value = 1;
		} else if (availBool.value === 1) {
			availBool.value = 0;
		}

		const params = {
			working_hours: availBool.value,
		};

		try {
			const res = await api.adminSettingsEdit(params);
			console.warn(res.data);
			setLocalStorageReac("setting-avail", availBool.value);
			getAdminSettings();
			return true;
		} catch (err: any) {
			console.warn(err.message);
			return false;
		}
	}

	async function getAdminSettings() {
		const storageNotif = getLocalStorageReac("setting-notif")?.value as string;
		const storageAvail = getLocalStorageReac("setting-avail")?.value as string;

		if (storageNotif === null || storageAvail === null) {
			const params = {
				from: "admin",
			};

			try {
				const res = await api.adminSettingsGet(params);
				// console.log(res.data.data);
				notifBool.value = res.data.data[0].is_active ? 1 : 0;
				availBool.value = res.data.data[1].is_active ? 1 : 0;
				setLocalStorageReac("setting-notif", notifBool.value);
				setLocalStorageReac("setting-avail", availBool.value);
				return true;
			} catch (err: any) {
				console.warn(err.message);
				return false;
			}
		} else {
			notifBool.value = parseInt(storageNotif, 10);
			availBool.value = parseInt(storageAvail, 10);
		}
	}

	function checkNotificationSupport(): boolean {
		if (!("Notification" in window)) {
			alert("This browser does not support desktop notifications.");
			return false;
		}
		return true;
	}

	async function askNotificationPermission(
		title?: string,
		body?: string,
		icon?: string,
		silent?: boolean,
	): Promise<void> {
		try {
			if (Notification.permission === "granted") {
				showNotification(title, body, icon, silent);
			} else if (Notification.permission !== "denied") {
				const permission = await Notification.requestPermission();
				if (permission === "granted") {
					showNotification(title, body, icon, silent);
				}
			}
		} catch (error) {
			console.error("Failed to get notification permission:", error);
		}
	}

	function showNotification(
		title?: string,
		body?: string,
		icon?: string,
		silent?: boolean,
	): void {
		new Notification(title, {
			body: body,
			icon: icon,
			lang: "en-US",
			requireInteraction: true,
			silent: silent,
			badge: notifIcon,
			tag: "new-message",
		});
	}

	function onClickNotification(
		title: string = "",
		body: string = "",
		icon: string = notifIcon,
		silent: boolean = true,
	) {
		if (checkNotificationSupport()) {
			askNotificationPermission(title, body, icon, silent);
		}
	}

	// Initialize WebSocket
	function initWs(userToken: string) {
		const TEST_URL = `https://${domain}/api/chatmessages/chatusers-ws?jwt=${userToken}`;
		const options: ConstructorParameters<typeof CWebsocket>[1] = {
			cb: {
				onOpen(ws, evt) {
					console.log("WebSocket Opened");
				},
				onMessageData: (ws, evt, data) => {
					wsText.value = ws.logs.join("\n");
					// Process incoming data
					const message = (data as unknown as { message: string }).message;
					let dataMessage = JSON.parse(message);

					switch (dataMessage.type) {
						case "message": {
							const chatUserIndex = notificationsByChat.value?.findIndex(
								(user) => user.cus_id === dataMessage.data.cus_id,
							);

							if (dataMessage.data.cus_id === selectedUserId.value) {
								addMessage(
									dataMessage.data.cms_message,
									dataMessage.data.cms_date,
									dataMessage.data.cms_from ?? "user",
									dataMessage.data.cus_id,
								);
							} else {
								let silent = false;
								if (notifBool.value === 1) silent = true;

								onClickNotification(
									"New message",
									"You have received a new message.",
									null,
									silent,
								);

								// Update notifications
								totalNotifications.value += 1;

								if (chatUserIndex !== -1 && notificationsByChat.value) {
									// If the user is on the list, update their notif count
									notificationsByChat.value[chatUserIndex].total_cms += 1;
								} else {
									// If the user is not on the list, add the user
									notificationsByChat.value?.push({
										cus_id: dataMessage.data.cus_id,
										total_cms: 1,
									});
								}
							}

							const userIndex = chatusers.value?.findIndex(
								(user) => user.cus_id === dataMessage.data.cus_id,
							);

							if (userIndex !== -1 && chatusers.value) {
								chatusers.value[userIndex].message_count += 1;
							}

							break;
						}
						case "new_chatuser": {
							// console.log(dataMessage.data);
							const item = {
								cus_id: dataMessage.data.cus_id,
								cus_token: dataMessage.data.cus_token,
								cus_utc_last_activity: dataMessage.data.cus_utc_last_activity,
								cus_referrer: dataMessage.data.cus_referrer,
								cus_city: dataMessage.data.cus_city,
								cus_country_code: dataMessage.data.cus_country_code,
								cus_label: null,
								message_count: 0,
							};
							// console.log(item);
							chatusers.value.unshift(item);

							break;
						}
						case "change_label": {
							const userIndex = chatusers.value?.findIndex(
								(user) => user.cus_id === dataMessage.data.cus_id,
							);

							if (userIndex !== -1 && chatusers.value) {
								chatusers.value[userIndex].cus_label = dataMessage.newLabel;
							}

							break;
						}
						case "typing": {
							if (dataMessage.data.cus_id === selectedUserId.value) {
								typingBool.value = true;
								newMsgCounter.value += 1;
							}

							break;
						}
						case "not_typing": {
							if (dataMessage.data.cus_id === selectedUserId.value) {
								typingBool.value = false;
								newMsgCounter.value += 1;
							}

							break;
						}
						// No default
					}
				},
				onError(ws, evt) {
					console.error("WebSocket Error", evt);
					wsText.value = ws.logs.join("\n");
				},
				onClose(ws, evt) {
					console.log("WebSocket Closed", evt);
					wsText.value = ws.logs.join("\n");
				},
			},
			useLogs: true,
			autoReconnect: true, // Enable auto-reconnect
			retries: 5, // Maximum retries for reconnection
			delayReconnect: 2000, // Delay between retries
		};
		wsInstance.value = new CWebsocket(TEST_URL, options);
		wsText.value = wsInstance.value.logs.join("\n");
	}

	function addMessage(
		message: string,
		date: string,
		from: "user" | "admin",
		cus_id: number,
	) {
		const item = {
			cms_message: message,
			cms_date: date,
			cms_from: from,
			cus_id: cus_id,
		};
		chatMessages.value.push(item);
		newMsgCounter.value += 1;
	}

	return {
		getChatNotifications,
		totalNotifications,
		chatsNotifications,
		notificationsByChat,
		notifBool,
		availBool,
		enableNotif,
		setAvailable,
		getAdminSettings,
		onClickNotification,
		initWs,
		wsInstance,
		chatusers,
		selectedUserId,
		chatMessages,
		addMessage,
		newMsgCounter,
		typingBool,
	};
});
